import { Button } from "bootstrap";
import React, { useContext, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  MdArrowBackIosNew,
  MdKeyboardArrowLeft,
  MdOutlineSubject,
} from "react-icons/md";
import { HiOutlineRefresh } from "react-icons/hi";
import MenuSidebar from "./MenuSidebar";
import AuthContext from "../context/AuthContext";
import { isEmpty } from "lodash";
import { GiHamburgerMenu } from "react-icons/gi";
const Header = () => {
  let hostname = window.location.hostname;
  hostname = hostname.replace(/^www\./, "");
  hostname = hostname.replace(/^ag\./, "");
  hostname = hostname.replace(/^msa\./, "");


  let {
    user,
    showSidebar,
    handleShowSidebar,
    userCoins,
    amounutRefresh,
    refreshAmountLoader,
    getCurrentBetCount,
  } = useContext(AuthContext);
  const navigte = useNavigate();
  const location = useLocation();
  return (
    <div
      id="header"
      className={
        user
          ? "header bg-yellow d-flex justify-content-between before-login"
          : "header bg-yellow d-flex justify-content-between"
      }
    >
      <div className="logo-sec d-flex align-items-center">
        {user && location?.pathname?.split("/")[1] !== "full-market" ? (
          <button
            className="bg-transparent border-0"
            onClick={() => {
              handleShowSidebar();
              getCurrentBetCount();
            }}
          >
            {/* <GiHamburgerMenu size={20} className="fs-4" /> */}
            <i class="icon-menu text-20 text-header-icon"></i>
          </button>
        ) : location?.pathname === "/" ? (
          ""
        ) : (
          <button
            className="bg-transparent border-0"
            onClick={() => navigte(-1)}
          >
            <MdArrowBackIosNew className="fs-4" />
          </button>
        )}

        <Link to="/">
          <img src="../../assets/images/velki-logo.png" alt="" />
        </Link>
      </div>

      {!isEmpty(user) ? (
        <div className="d-flex align-items-center after-login">
          {refreshAmountLoader ? (
            <div class="loader-outer">
              <span
                class="loader-inner-icon"
                style={{ animationDelay: "0s" }}
              ></span>
              <span
                class="loader-inner-icon"
                style={{ animationDelay: ".1s" }}
              ></span>
              <span
                class="loader-inner-icon"
                style={{ animationDelay: ".2s" }}
              ></span>
              <span
                class="loader-inner-icon"
                style={{ animationDelay: ".3s" }}
              ></span>
              <span
                class="loader-inner-icon"
                style={{ animationDelay: ".4s" }}
              ></span>
              <span
                class="loader-inner-icon"
                style={{ animationDelay: ".5s" }}
              ></span>
              <span
                class="loader-inner-icon"
                style={{ animationDelay: ".6s" }}
              ></span>
              <span
                class="loader-inner-icon"
                style={{ animationDelay: ".7s" }}
              ></span>
            </div>
          ) : (
            <div>
              <span>{user?.user?.username}</span>
              <div className="d-flex align-items-center ">
                <strong className="header-font-size">
                  PBU <span>{userCoins?.balance?.toFixed(2)}</span>
                </strong>
                <strong className="header-font-size">
                  Exp{" "}
                  <span className="text-danger" style={{ fontWeight: "700" }}>
                    ({userCoins?.exp?.toFixed(2)})
                  </span>
                </strong>
              </div>
            </div>
          )}
          <i
            onClick={amounutRefresh}
            style={{ fontSize: "1.4285714285714286rem" }}
            className={`${
              refreshAmountLoader ? "rotate" : ""
            } icon-refresh icon`}
          />
        </div>
      ) : (
        <div className="social-header">
          <Link to={hostname=="velkicx123.live"?"https://Velkiagentall.live ":"https://myVelki.com/"} className="signup">
            <i style={{ fontSize: "1rem" }} class="icon-sign-up"></i>
            Sign Up
          </Link>
          <Link to="/login" className="login">
            <i style={{ fontSize: "1rem" }} class="icon-login"></i>
            Login
          </Link>
        </div>
      )}

      {showSidebar && (
        <MenuSidebar cancelMenu={handleShowSidebar} showSidebar={showSidebar} />
      )}

      {showSidebar && (
        <div className="overlay" onClick={() => handleShowSidebar()}></div>
      )}
    </div>
  );
};

export default Header;
